import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./advantageous-pricing.module.css";

export default function AdvantageousPricing() {
  return (
    <Layout>
      <Helmet>
        <title>Advantageous Pricing | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Advantageous Pricing</h1>
          <p>Allowing larger merchants to pay lower fees</p>
        </div>

        <div className={baseStyles.pageContentInner}>
          <div className={styles.contentRoot}>
            <p>
              Advantageous pricing is a billing method used by My EZ Solutions
              to give you complete transparency and greater savings. When you
              process a transaction we pass the interchange and card-network
              costs directly on to you, giving you access to the true wholesale
              rates. For our service, we charge you a set margin on top of the
              transaction. The more you process, the more we reduce our margin.
            </p>
            <p>
              Many old-school processors use "tiered" pricing, where you are
              charged a base "qualified" rate and then charged a "non-qualified"
              fee for most transactions. This results in rates that are much
              higher than needed. By passing the cost directly to you and only
              charging for a single markup, interchange plus pricing gives you
              significant savings while creating a very transparent arrangement
              with your processor - you know our cost and margin!
            </p>
            <a className={baseStyles.heroCta} href={"/get-quote"}>
              Get Started
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
